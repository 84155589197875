import React from "react";
import { useDispatch, useSelector } from "react-redux";
import overlay from "../../../assets/images/bg-auth-overlay.jpg";
import { useState, useEffect } from "react";
import { getUserList, updatestatusAction, deleteUserAction } from "../../../Redux/Actions/auth"; // Import delete action
import { useNavigate } from "react-router";
import Layout from "../../components/Layout";
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/loading/Loading';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

export const UserManagement = () => {
  const [search, setSearch] = useState("");
  const [status_id, setStatus_id] = useState("");
  const [status, setStatus] = useState("");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // New state for delete modal
  const [userIdToDelete, setUserIdToDelete] = useState(null); // Store user id to delete
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [userlist, setUserlist] = useState([]);
  const [pagination, setPagination] = useState({});
  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  // const userlist = useSelector((state) => state?.adminSlice?.userList);
  const nevigate = useNavigate();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       await dispatch(getUserList());
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [dispatch]);

  const fetchSelfieData = async () => {
    setLoading(true);
    try {
      // Add the `search` parameter dynamically
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}userlist`,
        {
          params: {
            page,
            limit,
            search, // Include search term in query params
          },
        }
      );

      setUserlist(response.data.data); // Set user data
      setPagination(response?.data?.pagination); // Set pagination info
    } catch (error) {
      console.error("Error fetching selfie data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setPage(1);
  }, [search]);
  useEffect(() => {
    fetchSelfieData();
  }, [page, limit, search]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = async (id, status) => {



    if (status === 1) {
      setIsChecked(!isChecked);
      await dispatch(updatestatusAction({
        id: id,
        status: 1
      }))
      setShowStatusModal(false)
      await fetchSelfieData()
    } else {
      setIsChecked(!isChecked);
      await dispatch(updatestatusAction({
        id: id,
        status: 0
      }))
      setShowStatusModal(false)
      await fetchSelfieData()
    }
  }

  // New delete function
  const handleDelete = async () => {
    if (!userIdToDelete) return;
    
    setLoading(true);
    try {
      await dispatch(deleteUserAction(userIdToDelete));
      await fetchSelfieData(); // Refresh the list
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
      setUserIdToDelete(null);
    }
  };

  // Function to open delete confirmation modal
  const confirmDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  return (
    <>
      {loading && <Loading />}
      <Layout>
        <div id="layout-wrapper">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">User Management</h4>
                        <div className="d-flex justify-content-between">
                          <label className="sort-drop"></label>
                          <label className="search-filter">
                            Search:{" "}
                            <input
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              name="search"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="datatable"
                            />
                          </label>
                        </div>
                        <div className="table-responsive custom_scroll">
                          <table
                            id="datatable"
                            className="table table-bordered dt-responsive  nowrap w-100"
                          >
                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Country</th>
                                <th>Status</th>
                                <th>Actions</th> {/* Changed from Action to Actions */}
                              </tr>
                            </thead>
                            <tbody>
                              {userlist && userlist.length > 0 ? (
                                userlist.map((item, i) => (
                                  <tr key={i}>
                                    <td>{(page - 1) * limit + i + 1}</td>
                                    <td>
                                      <div className="profile-img-circle">
                                        <img
                                          src={HOST_NAME + item?.user_image[0]?.main_image}
                                          alt="User"
                                        />
                                      </div>
                                    </td>
                                    <td
                                      onClick={() => {
                                        nevigate(`/profile-view/${item?._id}`);
                                      }}
                                    >
                                      <div
                                        className="user-name"
                                        data-bs-toggle="modal"
                                        data-bs-target=".bs-example-modal-lg"
                                      >
                                        {item?.first_name}
                                      </div>
                                    </td>
                                    <td>{item?.email}</td>
                                    <td>{item?.country_code + " " + item?.phone_number}</td>
                                    <td>{item?.country}</td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <span className="me-2">
                                          {item?.status == 0 ? "Active" : "Suspended"}
                                        </span>
                                        <Form.Check
                                          type="switch"
                                          id={`toggle-switch-${i}`}
                                          checked={item?.status == 0}
                                          onChange={() => {
                                            setShowStatusModal(true);
                                            setStatus_id(item?._id);
                                            setStatus(item?.status == 0 ? 1 : 0);
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <button 
                                        className="btn btn-danger btn-sm"
                                        onClick={() => confirmDelete(item?._id)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                          <div className="showing-results">
                            Showing {((page - 1) * limit) + 1} to {Math.min(page * limit, pagination?.total)} of {pagination?.total} entries
                          </div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={pagination?.totalPages || 1}
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            forcePage={page - 1}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Status Update Modal */}
        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to change the status?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              handleToggle(status_id, status)
            }}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        
        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this user? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
};
