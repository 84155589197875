import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Api/Api";
export const adminLogin = createAsyncThunk(
  "adminlogin",
  async (data, thunkApi) => {
    const response = await Api.post("/Adminlogin", data);
    localStorage.setItem('accessToken',response.data.token)
    return response.data;
  }
);
export const getUserList = createAsyncThunk(
  "getuserlist",
  async (data, thunkApi) => {
    const response = await Api.get("/userlist", data);
    return response.data;
  }
);

export const profileDetail = createAsyncThunk(
    "profileDetail",
    async (user_id, thunkApi) => {
      const response = await Api.get(`/Profile_detail?user_id=${user_id}`);
      return response.data;
    }
  );

  export const SelfieApproved = createAsyncThunk(
    "SelfieApproved",
    async (data, thunkApi) => {
      const response = await Api.post("/SelfieApproved", data);
      return response.data;
    }
  );
  export const reportList = createAsyncThunk(
    "reportList",
    async (data, thunkApi) => {
      const response = await Api.get("/report_list", data);
      return response.data;
    }
  );
  export const closeReport = createAsyncThunk(
    "closeReport",
    async (id, thunkApi) => {
      const response = await Api.delete(`/close_report?report_id=${id}`);
      return response.data;
    }
  );
  export const reportDelete = createAsyncThunk(
    "reportDelete",
    async (id, thunkApi) => {
      const response = await Api.delete(`/delete_report?id=${id}`);
      return response.data;
    }
  );


  export const updatestatusAction = createAsyncThunk(
    "updatestatus",
    async (data, thunkApi) => {
      const response = await Api.post("/updatestatus", data);
      return response.data;
    }
  );

export const deleteUserAction = createAsyncThunk(
  "deleteUser",
  async (userId, thunkApi) => {
    try {
      const response = await Api.delete(`/delete-permanent/${userId}`);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);