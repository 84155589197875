import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { deleteUserAction, getUserList, SelfieApproved } from '../../../Redux/Actions/auth';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Layout from '../../components/Layout';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../components/loading/Loading';
import ReactPaginate from 'react-paginate';
import axios from 'axios';

export default function Selfi_Verification() {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [reasone, setReasone] = useState("");
  const [setobject, setSetobject] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [userlist, setUserlist] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false); // New state for delete modal
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  const nevigate = useNavigate();
  const dispatch = useDispatch();

  const fetchSelfieData = async () => {
    setLoading(true);
    try {
      // Add the `search` parameter dynamically
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}userlist`,
        {
          params: {
            selfie_approved: true,
            page,
            limit,
            search // Include search term in query params
          },
        }
      );

      setUserlist(response.data.data); // Set user data
      setPagination(response.data.pagination); // Set pagination info
    } catch (error) {
      console.error("Error fetching selfie data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setPage(1); // Reset to first page
  }, [search]);
  // Trigger API call when `page`, `limit`, or `search` changes
  useEffect(() => {
    fetchSelfieData();
  }, [page, limit, search]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const update_status = (id, status) => {
    dispatch(SelfieApproved({
      id: id,
      status: status,
      reasone: reasone
    })).then(function (data) {
      dispatch(getUserList());
      fetchSelfieData();
      setShow(false)
      toast.success(data.status == 200 ? data.message : data.message)
    })
  }

  const handleDelete = async () => {
    if (!userIdToDelete) return;
    
    setLoading(true);
    try {
      await dispatch(deleteUserAction(userIdToDelete));
      await fetchSelfieData(); // Refresh the list
      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user");
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
      setUserIdToDelete(null);
    }
  };

  // Function to open delete confirmation modal
  const confirmDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      {loading && <Loading />}
      <Layout>
        <div id="layout-wrapper">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Selfie Verification</h4>
                        <div className="d-flex justify-content-between">
                          <label className="sort-drop">


                          </label>

                          <label className="search-filter">
                            Search:{" "}
                            <input
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              name="search"
                              class="form-control form-control-sm"
                              placeholder=""
                              aria-controls="datatable"
                            />
                          </label>
                        </div>
                        <div className="table-responsive custom_scroll">
                          <table
                            id="datatable"
                            className="table table-bordered dt-responsive  nowrap w-100"
                          >

                            <thead>
                              <tr>
                                <th>Sr no.</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Country</th>
                                <th>status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {userlist && userlist.length > 0 ? (
                                userlist
                                  // Uncomment and use the filter if needed
                                  // .filter((item) => {
                                  //   const searchTerm = search.toLowerCase();
                                  //   return (
                                  //     item?.first_name?.toLowerCase().includes(searchTerm) ||
                                  //     item?.email?.toLowerCase().includes(searchTerm) ||
                                  //     (item?.country_code + " " + item?.phone_number)?.includes(searchTerm)
                                  //   );
                                  // })
                                  .map((item, i) => (
                                    <tr key={i}>
                                      <td>{(page - 1) * limit + i + 1}</td>
                                      <td>
                                        <div className="img-td">
                                          <img
                                            src={HOST_NAME + item?.selfie_pic}
                                            alt="Selfie"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        onClick={() => nevigate(`/profile-view/${item?._id}`)}
                                      >
                                        <div
                                          className="user-name"
                                          data-bs-toggle="modal"
                                          data-bs-target=".bs-example-modal-lg"
                                        >
                                          {item?.first_name}
                                        </div>
                                      </td>
                                      <td>{item?.email}</td>
                                      <td>{item?.country_code + " " + item?.phone_number}</td>
                                      <td>{item?.country}</td>
                                      <td className="yellow_text green_text">
                                        {item?.selfie_approved === 0
                                          ? "Pending Approval"
                                          : item?.selfie_approved === 2
                                            ? "Rejected"
                                            : "Approved"}
                                      </td>
                                      <td>
                                        <button
                                          onClick={() => update_status(item?._id, 1)}
                                          className="btn btn-success btn-sm ml-3 mr-4"
                                        >
                                          Accept
                                        </button>
                                        <button
                                          className="btn btn-danger btn-sm mr-6 mr-4"
                                          onClick={() => {
                                            setSetobject(item?._id);
                                            handleShow();
                                          }}
                                        >
                                          Reject
                                        </button>
                                        <button 
                                        className="btn btn-danger btn-sm ml-6"
                                        onClick={() => confirmDelete(item?._id)}
                                      >
                                        Delete
                                      </button>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td colSpan="8" className="text-center">
                                    No data found
                                  </td>
                                </tr>
                              )}

                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                          <div className="showing-results">
                            Showing {((page - 1) * limit) + 1} to {Math.min(page * limit, pagination?.total)} of {pagination?.total} entries
                          </div>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={pagination?.totalPages || 1}
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                            forcePage={page - 1}
                            containerClassName="pagination"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Reasone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Write Reason</Form.Label>
                <Form.Control


                  as="textarea" rows={3}
                  onChange={(e) => {
                    setReasone(e.target.value)
                  }}
                />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => {
              update_status(setobject, 2)
            }}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this user? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  )
}
